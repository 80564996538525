import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/Seo"
import PostCover from "../components/PostCover"
import PostContent from "../components/PostContent"
import PostListGroupTitle from "../components/PostListGroupTitle"
import RelatedPosts from "../components/RelatedPosts"
import Breadcrumb from "../components/Breadcrumb"
import { graphql, withPrefix } from "gatsby"
import Gql from "../typings/gql"
import { format, parseISO } from "date-fns";
import { DATE_FORMAT_STRING } from "../constants";
import { AppContext } from "../utils/appContext";
import Helmet from "react-helmet";
import { useEffect } from "react";
import { loadAndAwaitScript, basicStripHtmlTags } from "../utils/misc";
import { useIntl } from "react-intl"
import { getRoute } from "../utils/i18n"

interface Props {
    data: Gql.PostQuery;
    pageContext: {
        slug: string;
        id: string;
    } & AppContext;
}

const PostPage: React.FC<Props> = ({ data, pageContext: { categories, mainMenu, mainSiteUrl } }) => {
    if (!data.wordpressPost) return null;

    const post = data.wordpressPost;
    const category = post.categories?.[0];

    const { formatMessage: fm } = useIntl();

    useEffect(() => {
        (async () => {
            await loadAndAwaitScript("//ajax.googleapis.com/ajax/libs/jquery/1.9.1/jquery.min.js");
            await loadAndAwaitScript(withPrefix("/js/jquery.magnific-popup.min.js"));
            window.$(".gallery").magnificPopup({
                delegate: "a",
                type: "image"
            })
            await loadAndAwaitScript("//cdn.iframe.ly/embed.js");
            document.querySelectorAll("#blog-post-content script").forEach(scriptTag => {
                eval(scriptTag.innerHTML);
            })
        })();
    }, []);

    return (
        <Layout categories={categories} mainMenu={mainMenu} mainSiteUrl={mainSiteUrl}>
            <Helmet>
                <link rel="stylesheet" href={withPrefix("/css/magnific-popup.css")} />
            </Helmet>
            <SEO ogTitle={post.title} ogDescription={post.excerpt && basicStripHtmlTags(post.excerpt)} ogImage={post.featured_media?.source_url ?? withPrefix("/images/placeholder.jpg")} />
            <div className="main wave">
                <Breadcrumb breadcrumb={[{ title: `${category?.acf?.display_name}`, url: `/${category?.acf?.acf_slug}` }, { title: `${post.title}`, url: `/${getRoute("article")}/${post.slug}` }]} />
                <PostCover
                    coverSrc={post.featured_media?.source_url}
                    title={post.title || ""}
                    author={post.author?.name || ""}
                    date={format(parseISO(post.date), DATE_FORMAT_STRING)}
                    lead={""}
                />
                <PostContent
                    post={post}
                />
                <div style={{ height: "40px" }}>&nbsp;</div>
                {/* <PostListGroupTitle title={fm({ id: "post.relatedposts" })} /> */}
            </div>
            {/* <RelatedPosts />
            <RelatedPosts /> */}

        </Layout>
    )
}

export default PostPage

export const pageQuery = graphql`
    query Post($slug: String!) {
        wordpressPost(slug: {eq: $slug}) {
            excerpt
            content
            title
            slug
            categories {
                acf {
                    acf_slug
                    display_name
                    category_icon
                }
            }
            date
            modified
            tags {
                name
                slug
            }
            featured_media {
                source_url
            }
            author {
                id
                name
                avatar_urls {
                    wordpress_96
                }
                slug
                description
            }
        }
    }
`;
