//import { Link } from "gatsby"
//import PropTypes from "prop-types"
import React from "react"

import cm from "./PostCover.module.scss"
import { withPrefix } from "gatsby"
interface Props {
    coverSrc?: string | null;
    title: string;
    lead: string;
    author: string;
    date: string;
}
const PostCover: React.FC<Props> = ({ coverSrc, title, lead, author, date }) => {
  return (
    <div className={cm.postCover}>
        <div className="row collapse">
            <div className={`columns ${cm.postCoverContent}`}>
                <img src={coverSrc || withPrefix("/images/placeholder.jpg")} alt="" />

                <div className={cm.text}>
                    <h2>{title}</h2>
                    <div className={cm.lead} dangerouslySetInnerHTML={{ __html: lead }} />
                    <div className={cm.info}>
                        <span className={cm.author}>
                            {author}
                        </span>
                        <span className={cm.date}>
                            {date}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default PostCover
