//import { Link } from "gatsby"
//import PropTypes from "prop-types"
import React from "react"

import cm from "./PostContent.module.scss"
import Categories from "./Categories"
import Tags from "./Tags"
import AuthorBlock, { AuthorBlockView } from "./AuthorBlock"
import Gql from "../typings/gql"
import { getLang } from "../utils/i18n"
import { useIntl } from "react-intl"

interface Props {
  post: NotNull<Gql.PostQuery["wordpressPost"]>
}

const PostContent: React.FC<Props> = ({
  post: { content, author, tags, categories },
}) => {
  const { formatMessage: fm } = useIntl()
  return (
    <div className={cm.postContent}>
      <div className="row collapse">
        <div className="columns">
          <div className={cm.postContainer}>
            <div className={cm.postContentLeftSide}>
              <div id="blog-post-content" dangerouslySetInnerHTML={{ __html: content || "" }} />

              {author && getLang() !== "hu" && (
                <AuthorBlock view={AuthorBlockView.Post} author={author} />
              )}
            </div>
            <div className={cm.postContentRightSide}>
              {tags?.length && (
                <>
                  <h4>Cimkék:</h4>
                  <Tags tags={tags} />
                </>
              )}
              <h4>{fm({ id: "common.categories" })}:</h4>
              <Categories displayedCategories={categories || []} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PostContent
