//import { Link } from "gatsby"
//import PropTypes from "prop-types"
import React from "react"
import { Link } from "gatsby"
import cm from "./Tags.module.scss"
import Gql from "../typings/gql"
import { getRoute } from "../utils/i18n"

interface Props {
    tags: Gql.Maybe<Gql.Maybe<Pick<Gql.Wordpress__Tag, "name" | "slug">>[]>;
}

const Tags: React.FC<Props> = ({ tags }) => {
    return (
        <div className={cm.labels}>
            {tags?.map(tag =>
                <Link to={`/${getRoute("search")}?keyword=${tag?.name}`} className={cm.label}>{tag?.name}</Link>
            )}
        </div>
    )
}

export default Tags
